import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import HeroImage from "../images/journey-hero.png"; 
import AlgorithmIcon from "../assets/icons/algorithm-icon.svg";
import MarketingAutomationImage from "../images/marketing-automation.png"; 
import CodecircleIcon from "../assets/icons/code-circle-icon.svg"; 
import MediaManagerImage from "../images/Omnichannel.png"; 
import OnePlatformImage from "../images/one-platform-updated.png";
import JourneyCustomerImage from "../images/journey-customer.png";
import CloudConnectionIcon from "../assets/icons/cloud-connection-icon.svg"; 
import CloudIcon from "../assets/icons/cloud-icon.svg"; 
import CTABannerImage from "../images/journey-cta.png";
import BuildingIcon from "../assets/icons/buildings-icon.svg";
import MerchandIcon from "../assets/icons/merchandise-icon.svg";
import JourneyIcon from "../assets/icons/journey-icon.svg";
import PersonalizeIcon from "../assets/icons/personalize-icon.svg";
import ContentWebflowIcon from "../assets/icons/content-workflow-icon.svg"; 
import PeopleIcon from "../assets/icons/people-icon.svg"; 
import UnifyIcon from "../assets/icons/unify-icon.svg";
import RealtimeGraphIcon from "../assets/icons/realtime-graph-icon.svg";
import DataIcon from "../assets/icons/data-icon.svg"; 
import EmailIcon from "../assets/icons/email-icon.svg"; 
import ChatIcon from "../assets/icons/chat-icon.svg"; 
import TrackIcon from "../assets/icons/track-icon.svg"; 
import BoxIcon from "../assets/icons/box-icon.svg"; 
import VideoIcon from "../assets/icons/video-icon.svg"; 
import SearchIcon from "../assets/icons/search-icon.svg"; 
import CalenderIcon from "../assets/icons/calender-icon.svg";
import ChartIcon from "../assets/icons/chart-icon.svg";
import UpdateIcon from "../assets/icons/update-icon.svg";
import AutomationIcon from "../assets/icons/automation-icon.svg";
import MarketingIcon from "../assets/icons/marketing-icon.svg";
import TrackingReportIcon from "../assets/icons/tracking-report-icon.svg";
import WhatsappIcon from "../assets/icons/whatsapp-icon.svg";
import StopwatchIcon from "../assets/icons/stopwatch-icon.svg";  
import IntegrateIcon from "../assets/icons/integrate-icon.svg";  
import ShieldSecurityIcon from "../assets/icons/shield-security-icon.svg";  


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const JourneyPage = () => {
    return (
        <div className="template-cms journey-page header-position">
            <Helmet>
                <title>Automate your entire customer journey using Experro's CDP</title>
                <description>Use an advanced CDP (Customer Data Platform) to manage your visitor's interaction and deliver personalized solutions.</description>
            </Helmet>
            <Layout>
                <div className="hero-banner mb-208">
                    <div className="hero-banner-inner">
                        <div className="container">
                            <div className="content-block text-center">
                                <div className="banner-text">
                                    <h2>Turn customer data into ROI<br></br> and unlimited growth</h2>
                                    <p>A single platform to unify customer data, understand behaviors, and maximize the ROI by enabling hyper-personalized experiences at each stage of the customer journey</p>
                                    <Link className="button right-arrow" to="/contact/">Get a Demo</Link>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="image-block">
                        <div className="container">
                            <div className="image-hero-inner">
                                <img loading="lazy" src={HeroImage} title="Omnichannel Retail Experience" alt="omnichannel retail platform" />
                            </div> 
                        </div>
                    </div>
                </div>



                <div className="content-with-image d-flex align-v-center mb-104" id="customer-data-platform">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">Customer Data Platform</h3>
                            <p className="mb-80">Create accurate and comprehensive customer profiles by integrating disparate data, de-duplicating, and enriching records across any internal or external data sources to create one true source of customer information.</p>
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><UnifyIcon /></i> Unify Customer Data
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Integrate your customer data and touchpoints across customer profiles, orders, clicks, events, and third-party apps into a single source of truth.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><PeopleIcon /></i> 360 View of Customer
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Organize and enrich your customer data into a 360 View of each customer that has the power to personalize and transform marketing, sales, and service touchpoints.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><ContentWebflowIcon /></i> Smart Segmentation
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Use smart segmentation to find high-potential customers. Build your own segments based on customer attributes, behaviors, and revenue potential. </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><AlgorithmIcon /></i> Robust Data Security
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Control access to your most valuable customer data using the fine-grain role-based access control. Always have peace of mind with enterprise-grade data security, GDPR, and CCPA compliance.</p>
                                    </AccordionItemPanel>
                                </AccordionItem> 
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={JourneyCustomerImage} title="Customer Data Platform" alt="Customer Data Platform" />
                    </div>
                </div>


                <div className="flexible-content-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <RealtimeGraphIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Real-time Tracking</h6>
                                            <p className="small-text">Instantly capture every interaction as it happens to create near real-time view of every customer that can be used to enable in-the-moment experiences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <DataIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Extendable Data Model</h6>
                                            <p className="small-text">Flexible customer data model that allows for tracking custom attributes, events, and behaviors to address the changing needs of your business.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CodecircleIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>API Integrations</h6>
                                            <p className="small-text">Integrate third-party eCommerce systems, mobile apps, marketing tools, and much more to power them with rich customer data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="center-image-section mb-104" id="marketing-automation">
                    <div className="container">
                        <h3 className="section-title text-center">Marketing Automation</h3>
                        <p className="text-center mb-64">Help streamline marketing processes, avoid bottlenecks and enable teams to automate every touchpoint of the customer journey to run automated personalized campaigns</p>
                        <div className="image-block mb-104">
                            <img loading="lazy" src={MarketingAutomationImage} title="Visual Merchandising" alt="Visual Merchandising" />
                        </div>
                    </div>
                </div>

                <div className="icon-with-text-block icon-with-text-flex-box mb-208">
                    <div className="container">
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <EmailIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Email Campaigns</h6>
                                        <p className="small-text">Send personalized emails at the right time to drive repeat sales, improve retention and consistently grow your revenue.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <ChatIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Text Messaging</h6>
                                        <p className="small-text">Engage with your customer anytime and anywhere with targeted, personalized, and promotional text messages at scale.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <TrackIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Behavioral Targeting</h6>
                                        <p className="small-text">Use behavior-driven campaigns for cart abandonments, back-in-stock alerts, birthday coupons, suggested items, and much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-with-image left-side-image d-flex align-v-center mb-104" id="omnichannel-engagement">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">Omnichannel Engagement</h3>
                            <p className="mb-80">Reach each prospect and customer on the right channel, at the right time, with the right message across email, text, call, chat, storefront, and in-store.</p>
                            <Accordion className="toggle-block" preExpanded={['a']}> 
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><BoxIcon /></i> Drag-and-drop Journey Builder
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Build multi-channel customer journeys in minutes - just drag and drop the steps, click to configure them, and push them live.</p>
                                    </AccordionItemPanel>
                                </AccordionItem> 
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><VideoIcon /></i> Triggers, Conditions, and Actions
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Use the pre-built library of triggers, conditions, and actions to build multi-step, multi-day, and multi-channel marketing automation with unlimited possibilities. </p>
                                    </AccordionItemPanel>
                                </AccordionItem> 
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><SearchIcon /></i> Templates & Pre-built Journeys
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Get a headstart with our pre-built email templates and journeys. Use drag-and-drop template designs to create your own beautiful templates.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>  
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><CalenderIcon /></i> Advanced Personalization
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Make every conversation more effective by infusing personalized product recommendations, order details, and cart items into your marketing messages.</p>
                                    </AccordionItemPanel>
                                </AccordionItem> 
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={MediaManagerImage} title="Media Manager Image" alt="Media Manager Image" />
                    </div>
                </div>

                

                <div className="border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <ChartIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Analytics & Reporting</h6>
                                            <p className="small-text">Track and improve performance of your marketing campaigns with out-of-the-box data visualizations and performance dashboards.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <UpdateIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Data Automations</h6>
                                            <p className="small-text">Update and enrich your customer data in real-time by updating attributes, applying tags, and much more with built-in data actions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-64">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <AutomationIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Webhooks</h6>
                                            <p className="small-text">Integrate external services, send notifications, and send data updates to third-party systems with built-in webhook actions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MarketingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Consent Management</h6>
                                            <p className="small-text">Manage opt-in and opt-outs for various marketing campaigns. Use timezone, days, and business hours to improve compliance and marketing confidence.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <TrackingReportIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Monitoring Tools</h6>
                                            <p className="small-text">Use built-in tools to monitor campaign status, identify issues and optimize journeys - easy for marketers and powerful for developers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <WhatsappIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Social Media Integrations</h6>
                                            <p className="small-text">Engage your customers across social media channels such as WhatsApp, Facebook Messenger, Instagram, Facebook Ads, Google Ads, etc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

            
                <div className="content-with-image mb-104 journey-img-title">
                    <div className="container">
                        <div className="d-flex align-v-center">
                            <div className="left-block">
                                <div className="content-inner">
                                    <h3 className="section-title">One platform to turn your data into dollars.</h3>                            
                                </div>
                            </div>
                            <div className="right-block">
                                <img loading="lazy" src={OnePlatformImage} title="Content Ops" alt="Content Ops" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="scale-and-optimized-section pb-104 pt-104 yello-bg-color mb-208">
                    <div className="container">
                        <h3 className="section-title mb-80">Unleash the power of your customer data at scale - with speed, security, and confidence</h3>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CloudConnectionIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Infinitely Scalable</h6>
                                            <p className="small-text">Experro is designed to manage millions of customers and billions of data points with one unified platform.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BuildingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Enterprise Ready</h6>
                                            <p className="small-text">Empower enterprises to build memorable digital experiences and robust technology platforms at scale.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <StopwatchIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Get Started in Minutes</h6>
                                            <p className="small-text">Reduce time to market, costs, and effort required to build and offer personalization at scale. Use drag and drop widgets and UI to enable new experiences in minutes without IT dependencies.	</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <IntegrateIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Integrates with Existing Apps</h6>
                                            <p className="small-text">Integrate with your existing customer data and marketing stack - no need to rip and replace anything.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <ShieldSecurityIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Bullet-proof Security</h6>
                                            <p className="small-text">Industry-leading security and data encryption protocols at rest and in transit to prevent unauthorized access.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CloudIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Cloud Hosting</h6>
                                            <p className="small-text">Embrace Azure, GCP, AWS, or any cloud hosting service you choose to deliver faster, reliable, secure, and scalable commerce in record time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="cta-banner mb-208">
                    <div className="container">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CTABannerImage} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block pt-64 pb-64">
                                        <h4>See how Experro can empower your business to transform eCommerce.</h4>
                                        <p className="mb-40">We're helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/">Get a Demo</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="experro-more-details-section mb-208">
                    <div className="container">
                        <h3 className="mb-32 text-center font-medium">There is more to Experro</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Merchandising</h6>
                                            <p className="small-text">Display and arrange products in a way that makes sense for your eCommerce customers.</p>
                                            <Link className="btn-link right-arrow" to="/discovery/#visual-merchandising">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <PersonalizeIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Personalization</h6>
                                            <p className="small-text">Get right product in front of the right shopper with AI-powered Recommendations.</p>
                                            <Link className="btn-link right-arrow" to="/discovery/#personalized-recommendations">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <JourneyIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Visual Site Builder</h6>
                                            <p className="small-text">Create content intuitively and collaboratively with a visual editing experience.</p>
                                            <Link className="btn-link right-arrow" to="/cms/#visual-site-builder">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default JourneyPage;